<template>
  <div class="home">
    <Courses />
  </div>
</template>

<script>
// @ is an alias to /src
import Courses from '@/components/course/student/CourseStudent'

export default {
  name: 'StudentCoursesView',
  components: {
    Courses
  }
}
</script>
